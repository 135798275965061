// Header.tsx
import React, { useState } from 'react';
import { IconButton, Drawer, Box, Typography, Link } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/system';

const StyledHeader = styled('header')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
}));

const DrawerBox = styled(Box)(({ theme }) => ({
    width: 250,
    padding: theme.spacing(2),
}));

const Header: React.FC = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    return (
        <StyledHeader>
            <IconButton onClick={toggleDrawer(true)} size="large">
                <MenuIcon />
            </IconButton>
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                <DrawerBox
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <Typography variant="h6">
                        Acme Inc
                    </Typography>
                    {/* Adicione links aqui se necessário */}
                </DrawerBox>
            </Drawer>
            {/* Você pode adicionar mais conteúdo ao cabeçalho aqui se necessário */}
        </StyledHeader>
    );
};

export default Header;
