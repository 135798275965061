// App.tsx
import React, { Suspense } from 'react';
import Header from './Header';
import Footer from './Footer';
import TestCollapse from './TestCollapse';
const WelcomeSection = React.lazy(() => import('./WelcomeSection'));
// Importe outras seções com lazy loading conforme necessário

const App: React.FC = () => {
    return (
        <div style={{ minHeight: '100vh', backgroundColor: '#1a202c', color: 'white' }}>
            <Header />
            <main>
                <Suspense fallback={<div>Carregando...</div>}>
                    <WelcomeSection />
                    {/* Lazy load outras seções aqui */}
                </Suspense>
            </main>
            <Footer />
        </div>
    );
};

export default App;
