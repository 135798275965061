// Footer.tsx
import React from 'react';
import { Container, Typography, Grid, Link } from '@mui/material';
import { styled } from '@mui/system';

const StyledFooter = styled('footer')(({ theme }) => ({
    padding: theme.spacing(3, 0),
    borderTop: '1px solid #ccc',
}));

const Footer: React.FC = () => {
    return (
        <StyledFooter>
            <Container>
                <Grid container spacing={2} justifyContent="space-between">
                    <Grid item>
                        <Typography variant="body2">
                            © Aia Context. All rights reserved.
                        </Typography>
                    </Grid>
                    <Grid item>
                        {/* Aqui você pode adicionar links ou outros elementos, se necessário */}
                    </Grid>
                </Grid>
            </Container>
        </StyledFooter>
    );
};

export default Footer;
